import * as dateFns from "date-fns";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: { loadPath: "/locales/{{lng}}.32ec15e010353754eec0bcdf2abadba0.json" },
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        const [type, args] = format?.split("::") || [];

        if (type === "date") {
          return formatDate(value, args as DateFormatKey);
        }

        if (type === "unit") {
          return formatUnit(value, args as UnitFormatKey);
        }

        return value;
      },
    },
    lng: "fr",
    returnObjects: true,
  });
export default i18next;

const dateFormats = {
  day: (value: Date) => dateFns.format(value, "dd/MM/yyyy"),
  dayAndHour: (value: Date) => dateFns.format(value, "dd/MM/yyyy à HH:mm"),
  quarter: (value: string) => {
    const quarters = {
      Q1: "1er trimestre",
      Q2: "2ème trimestre",
      Q3: "3ème trimestre",
      Q4: "4ème trimestre",
    } as Record<string, string>;
    const [quarter, year] = value.split("/");
    return `${quarters[quarter]} ${year}`;
  },
};

type DateFormatKey = keyof typeof dateFormats;

function formatDate(value: string, args: DateFormatKey) {
  if (args === "quarter") {
    return dateFormats.quarter(value);
  }

  const date = new Date(value);
  return dateFns.isValid(date) ? dateFormats[args](date) : "";
}

const formatNumber = (value: string) =>
  value.replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, " ").replace(".", ",");

const unitFormats = {
  balance: (value: number) => {
    const sign = (() => {
      if (value === 0) {
        return "";
      }

      return value > 0 ? "+" : "-";
    })();
    return `${sign}${formatNumber(Math.abs(value).toString())} €`;
  },
  euro: (value: number) => `${formatNumber(value.toFixed(2))} €`,
  percent: (value: number) => `${formatNumber(value.toString())} %`,
  squareMeter: (value: number) => `${formatNumber(value.toString())} m²`,
};

type UnitFormatKey = keyof typeof unitFormats;

function formatUnit(value: number, args: UnitFormatKey) {
  return unitFormats[args](value);
}
