import { apiKeys } from "../../app/services/apiKeys";
import { Tracking as SharedTracking } from "../shared/Tracking";

export const Tracking = () => (
  <SharedTracking
    didomiApiKey={{
      noticeId: apiKeys.value.didomi,
      target: "Q7jWVFpX",
    }}
    gtmApiKey={apiKeys.value.gtm}
  />
);
